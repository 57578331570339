<template>
  <div class="main-container">
    <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>移机</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
    <div class="content-block">
      <am-detail :isBreadcrumb="false" :isMapShow="false" ></am-detail>
      <div class="content-title  separate-line">
        <p>移机后信息</p>
      </div>
      <div class="">
        <el-form ref="form" :model="form" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="5">
              <el-form-item label="医院">
                <el-select v-model="form.hospital_id" remote :remote-method="getHospitalOptions" filterable placeholder="请选择医院"  size="small" clearable>
                  <el-option
                      v-for="item in hospitalOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :filter-method="getHospitalOptions">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="位置">
                <el-select v-model="form.label_id" placeholder="请选择" size="small" clearable>
                  <el-option
                      v-for="item in locationTagOptions"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="新机号">
                <el-input size="small" v-model="form.replaced_instrument_id" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <div class="footer">
          <el-button type="primary" size="small" @click="submit(1)">提交</el-button>
          <el-button size="small" @click="submit(0)">暂存</el-button >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssetsDetail from "@/views/AssetManagement/components/assetsDetail.vue";
import AmDetail from "@/views/AssetManagement/amDetail.vue";
import {moveRecord,getMoveRecord} from "@/api/assetMgt";
import options from "@/views/AssetManagement/mixin/options";
import {hospitals_list} from "@/api/search";
export default {
  name: 'relocationRecord', // 移机记录
  components: {AssetsDetail,AmDetail},
  mixins: [options],
  data(){
    return{
      hospitalOptions: [],
      form:{
        instrument_id: this.$route.query.instrument_id, // 仪器机号
        opt_class:'', // 操作类型
        hospital_id:'', // 医院
        customize_label:'', // 仪器位置标签
        label_id: '', // 仪器位置标签
        remarks: '',
        replaced_instrument_id: '',

      },
    }
  },
  created() {
    this.getTempInfo()
  },
  methods:{
    getTempInfo(){
      let params = {
        instrument_id: this.$route.query.instrument_id,
        opt_class: 1404,
        is_submitted: 0
      }
      getMoveRecord(params).then(res=>{
        this.form = Object.assign(this.form, res.data)
        this.hospitalOptions = [
          {
            value: res.data.hospital_id,
            label: res.data.hospital_name
          }
        ]
      })
    },
    submit(type){ // 1400-入库,1401-出库,1402-发货,1403-装机,1404-移机,1405-解绑,1406-报废
      if(!this.form.hospital_id && this.form.label_id === '' && !this.form.replaced_instrument_id){
        return this.$message.error('请选填一项移机后信息！')
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.form.is_submitted = type
      // if(this.$route.query.asset_status == '使用中'){
      //   this.form.opt_class = 1404
      // }else if(this.$route.query.asset_status == '待返厂'){
      //   this.form.opt_class = 1405
      // }
      moveRecord(this.form).then(res => {
        this.goBack()
        loading.close();
      }).catch(err => {
        loading.close();
      })
    },
    getHospitalOptions(e){
      this.hospitalOptions = hospitals_list({ hospital_name: e })
    },
    getForm(val){
      this.form = val
    },
    goBack(){
      this.$router.push({ path: '/assetMgt/list' })
    },
    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}
</script>


<style scoped lang="scss">
@import '@/assets/css/asset';
.footer{
  text-align: center;
}
//特殊详情处理
.zone1{
  padding: 0;
}
::v-deep .el-tabs__header{
  padding-left: 10px;
}
::v-deep .el-input--small .el-input__inner{
  height: 40px;
  width: 100%;
}
</style>